<template>
  <div>
    <!-- Headline -->
    <HeroCurved class="how-payments-work" :heading="$t('how_payments_work.title')" :subheading="$t('how_payments_work.sub_title')" :button="false"/>

    <div class="main-container p-0">
      <div class="container-fluid mt-4 mb-3 mb-md-5">
        <h2 class="font-weight-bold justify-content-center pt-4">{{ $t('how_payments_work.steps_title') }}</h2>
        <div class="d-flex flex-row pt-0 pt-md-3 mt-0 mb-0 mb-md-3 justify-content-center">
          <b-col class="col-12 col-md-3 p-0 video overflow-hidden">
            <h1 class="font-weight-bold">{{ $t('how_payments_work.step_1.number') }}</h1>
            <p>{{ $t('how_payments_work.step_1.copy') }}</p>
          </b-col>
          <b-col class="offset-md-1 col-12 col-md-3 p-0 video overflow-hidden">
            <h1 class="font-weight-bold">{{ $t('how_payments_work.step_2.number') }}</h1>
            <p>{{ $t('how_payments_work.step_2.copy') }}</p>
          </b-col>
          <b-col class="offset-md-1 col-12 col-md-3 p-0 video overflow-hidden">
            <h1 class="font-weight-bold">{{ $t('how_payments_work.step_3.number') }}</h1>
            <span class="anchor" v-html="$t('how_payments_work.step_3.copy', { example_link: '#what-is-etn'})"></span>
          </b-col>
        </div>
      </div>
      <div class="w-100 position-relative d-flex flex-column justify-content-center align-items-center mb-3" style="background-color: #223C4A; color: white">
        <b-col class="col-12 col-md-9">
          <h2 class="font-weight-bold">{{ $t('how_payments_work.payment_protection.title') }}</h2>
          <p class="mb-0">{{ $t('how_payments_work.payment_protection.paragraphs.1') }}</p>
          <p v-html="$t('how_payments_work.payment_protection.paragraphs.2', { example_link: 'https://anytask.com/anytask-protection'})"></p>
        </b-col>
      </div>
      <div class="container-fluid products pt-0 pt-md-2 px-0 px-md-3">
        <!-- ETN -->
        <div id="what-is-etn" class="d-flex flex-row pt-0 pt-md-3 mt-0 mb-0 mb-md-3 etn">
          <!-- image -->
          <b-col order-md="1" class="col-12 col-md-3 image image-what-is-etn d-flex justify-content-center align-items-center mt-4 m-md-0">
            <img :src="require('@/assets/HowPaymentsWork/electroneum-logo-light.svg')" class="logo">
          </b-col>
          <!-- copy -->
          <b-col order-md="2" class="col-12 col-md-9 d-flex flex-column align-items-start pl-3 pl-md-5 px-4 px-md-0">
            <h3 class="font-weight-bold mb-4 mt-4 mt-md-0 w-100">{{ $t('how_payments_work.what_is_etn.title') }}</h3>
            <p class="text-left">{{ $t('how_payments_work.what_is_etn.paragraphs.1') }}</p>
            <p class="text-left">{{ $t('how_payments_work.what_is_etn.paragraphs.2') }}</p>
            <p class="text-left">{{ $t('how_payments_work.what_is_etn.paragraphs.3') }}</p>
            <div class="text-left">
              <span class="badge">{{ $t('how_payments_work.what_is_etn.list.1') }}</span>
              <span class="badge">{{ $t('how_payments_work.what_is_etn.list.2') }}</span>
              <span class="badge">{{ $t('how_payments_work.what_is_etn.list.3') }}</span>
              <span class="badge">{{ $t('how_payments_work.what_is_etn.list.4') }}</span>
              <span class="badge">{{ $t('how_payments_work.what_is_etn.list.5') }}</span>
              <span class="badge">{{ $t('how_payments_work.what_is_etn.list.6') }}</span>
              <span class="badge">{{ $t('how_payments_work.what_is_etn.list.7') }}</span>
            </div>
            <p class="text-left" v-html="$t('how_payments_work.what_is_etn.paragraphs.4')"></p>
          </b-col>
        </div>

        <div class="d-flex flex-row pt-0 pt-md-5 pb-5 etn">
          <b-col order-md="1" class="col-12 d-flex flex-column align-items-start pr-3 pr-md-5 px-4 px-md-0 text-left">
            <h3 class="font-weight-bold mb-4 mt-4 mt-md-0 w-100">{{ $t('how_payments_work.buy_with_etn.title') }}</h3>
            <p class="text-left">{{ $t('how_payments_work.buy_with_etn.paragraphs.1') }}</p>
            <ul class="dashed">
              <li> {{ $t('how_payments_work.buy_with_etn.paragraphs.2') }}</li>
              <li> {{ $t('how_payments_work.buy_with_etn.paragraphs.3') }}</li>
            </ul>
            <p>{{ $t('how_payments_work.buy_with_etn.paragraphs.4') }}</p>
          </b-col>
        </div>

        <!-- ETN -->
        <div class="d-flex flex-row pt-0 pt-md-5 pb-5 etn">
          <!-- copy -->
          <b-col order-md="1" class="col-12 col-md-9 d-flex flex-column align-items-start pr-3 pr-md-5 px-4 px-md-0 text-left">
            <h3 class="font-weight-bold mb-4 mt-4 mt-md-0 w-100">{{ $t('how_payments_work.get_paid_in_etn.title') }}</h3>
            <p class="text-left">{{ $t('how_payments_work.get_paid_in_etn.paragraphs.1') }}</p>
            <p class="text-left">{{ $t('how_payments_work.get_paid_in_etn.paragraphs.2') }}</p>
            <ul class="dashed">
              <li> {{ $t('how_payments_work.get_paid_in_etn.paragraphs.3') }}</li>
              <li> {{ $t('how_payments_work.get_paid_in_etn.paragraphs.4') }}</li>
              <li> {{ $t('how_payments_work.get_paid_in_etn.paragraphs.5') }}</li>
            </ul>
          </b-col>
          <!-- image -->
          <b-col order-md="2" class="col-12 col-md-3 image image-get-paid d-flex justify-content-center align-items-center mt-4 m-md-0"></b-col>
        </div>
      </div>

      <div class="w-100 position-relative d-flex flex-column justify-content-center align-items-center bg-secondary" style="color: white">
        <b-col class="col-12 col-md-9">
          <h2 class="font-weight-bold">{{ $t('how_payments_work.another_currency.title') }}</h2>
          <p class="mb-0">{{ $t('how_payments_work.another_currency.paragraphs.1') }}</p>
          <p class="mb-0" v-html="$t('how_payments_work.another_currency.paragraphs.2', { url: 'https://electroneum.com/exchanges/' })"></p>
        </b-col>
      </div>

      <div class="w-100 position-relative d-flex flex-column justify-content-center align-items-center bg-primary" style="color: white">
        <b-col class="col-12 col-md-9">
          <h2 class="font-weight-bold">{{ $t('how_payments_work.important_information.title') }}</h2>
          <p class="mb-0">{{ $t('how_payments_work.important_information.paragraphs.1') }}</p>
          <p class="mb-0">{{ $t('how_payments_work.important_information.paragraphs.2') }}</p>
          <p class="mb-0">{{ $t('how_payments_work.important_information.paragraphs.3') }}</p>
          <p class="mb-0">{{ $t('how_payments_work.important_information.paragraphs.4') }}</p>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCurved from '../components/Global/HeroCurved.vue'
import config from '../config'
import helper from '../helper'

export default {
  name: 'home',
  props: {
    image: Image,
    title: String,
    text: String
  },
  metaInfo () {
    return {
      title: 'AnyTask - Earn doing what you love',
      description: 'Sell your digital skills on AnyTask and earn ETN',
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'AnyTask - Earn doing what you love'
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://images.anytask.com/image/upload/b_rgb:ffffff,c_lpad,co_rgb:ffffff,f_jpg,h_630,w_1200/urbiknsbdw7r5mpntg5w.jpg'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Sell your digital skills on AnyTask and earn ETN'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.protocol + '//' + window.location.host + '/how-payments-work'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: config.anytasks_name
        }
      ]
    }
  },
  mounted () {
    helper.reorderMetas()
  },
  data () {
    return {
      tracking_switch: config.tracking_enabled === 'true',
      showYoutube: false,
      youtubeLink: 'https://www.youtube.com/embed/w8mMQwEEuog?autoplay=1&;enablejsapi=1',
      roleSeller: localStorage.getItem('role_seller'),
      accessToken: localStorage.getItem('accessToken')
    }
  },
  components: {
    HeroCurved
  }
}
</script>

<style lang="scss" scoped>
#content {
  background-color: white !important;
}

.main-container {
  background: white;
  .container-fluid {
    max-width: 1140px !important;
  }
  .w-100 {
    line-height: 1.675rem;
    margin: 0;
  }
  .w-100 p >>> a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  .w-100 p:last-child {
    margin: 0;
    padding-bottom: 2.25rem;
  }
  .w-100 h2 {
    padding: 2.25rem 0;
    margin: 0;
  }
  span.anchor >>> a {
    color: inherit;
    font-size: 0.8em;
  }
  .products {
    p {
      line-height: 1.675rem;
    }
    .etn {
      .image {
        border-radius: .5rem;
        height: 220px;
        .logo {
          width: 200px;
        }
      }
      .image-what-is-etn {
        background: url('~@/assets/HowPaymentsWork/what-is-etn.jpeg');
        background-size: cover;
      }
      .image-get-paid {
        background: url('~@/assets/HowPaymentsWork/how-get-paid.jpeg');
        background-size: cover;
      }
      ul {
        margin: 0;
        padding-left: 0;
      }
      ul.dashed {
        list-style-type: none;
      }
      ul.dashed > li {
        line-height: 1.675rem;
        text-indent: -8px;
        margin-bottom: 16px;
        padding-left: 15px;
      }
      ul.dashed > li:before {
        content: "-";
        text-indent: -5px;
      }
    }
    .badge {
      border: black 1px solid;
      padding: 6px;
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 1em;
    }
    .badge:last-child {
      margin-bottom: 16px;
    }
    h3 {
      text-align: left;
    }
  }
}

.hero {
  &.how-payments-work {
    background-image: $hero-mobile-shadow, url('~@/assets/HowPaymentsWork/how-payments-work-mobile.jpg');
    @include sm-up {
      background-image: url('~@/assets/HowPaymentsWork/how-payments-work-small.jpg');
    }
    @include md-up {
      background-image: url('~@/assets/HowPaymentsWork/how-payments-work-medium.jpg');
    }
    @include lg-up {
      background-image: url('~@/assets/HowPaymentsWork/how-payments-work-large.jpg');
    }

  }
}

</style>
